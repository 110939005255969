import { FiltrarQuaPorCursoETurma } from "./FiltrarQuaPorCursoETurma"

export default function FiltroGradesQuaPorNivel(gradesQua, curso_selecionado, criterio_avaliativo, digitacaoProf, valorTurma) {
    const nivelFiltro = gradesQua.filter(item =>
        item.Curso === curso_selecionado &&
        item.Nivel === criterio_avaliativo &&
        !(item.Grupo === 0 && item.SubGrupo === 0))


    const resultadoFiltro = FiltrarQuaPorCursoETurma(nivelFiltro, digitacaoProf, curso_selecionado, valorTurma)
    return resultadoFiltro
}