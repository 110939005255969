import './RelatorioCompleto.css'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Axios from 'axios'

import Header from '../../../components/Header/Header'
import SideBar from '../../../components/SideBar/SideBar'
import FormRelatorioAdmin from '../../../components/FormRelatorio/FormRelatorio'
import GetAvaliacoes from '../../../services/GetAvaliacoes'
import GetEmpresa from '../../../services/GetEmpresa'
import GetGrades from '../../../services/GetGrades'
import GetTurmas from '../../../services/GetTurmas'
import GetAlunos from '../../../services/GetAlunos'
import GetNotas from '../../../services/GetNotas'

import { Backdrop, CircularProgress } from '@mui/material'
import OrdemItem from '../../../Functions/OrdenarItens'
import { faixaDataMes } from '../../../Functions/FaixaDataMes'
import GetAlunosOptativas from '../../../services/GetAlunosOpByDisciplina'

const { ApiFilter } = require('../../../Functions/FilterApiDomain')



export default function RelatorioCompleto() {
    const [escola, setEscola] = useState(localStorage.getItem('escola').toUpperCase())
    const [idProfessor, setIdProfessor] = useState('Admin' + sessionStorage.getItem('idUser'))
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const [open, setOpen] = useState(false);
    const [cursosFiltro, setCursosFiltro] = useState([])
    const [disciplinaFiltro, setDisciplinaFiltro] = useState([])
    const [grades, setGrades] = useState([])
    const [turmas, setTurmas] = useState([])
    const [alunos, setAlunos] = useState([])
    const [faltasAlunos, setFaltasAlunos] = useState([])
    const [consultaConteudo, setConsultaConteudo] = useState([])
    const [DataCompleta, setDataCompleta] = useState(new Date().toLocaleDateString().split('/'))
    const [alunosFiltro, setAlunosFiltro] = useState([])
    const [DataAno, setDataAno] = useState('')
    const [DataMes, setDataMes] = useState('')
    const [DataDia, setDataDia] = useState('')
    const [empresa, setEmpresa] = useState([])
    const [disciplinasKeys, setDisciplinasKeys] = useState([])
    const [processing, setProcessing] = useState(false)
    const [avaliacoes, setAvaliacoes] = useState([])
    let notas = []
    let avaliacaoAbreviatura = ''

    const { register, handleSubmit, errors } = useForm()

    let checkEmBrancoAtual = false
    let paginaAtualGlobal = ''
    let paginasTotaisGlobal = ''

    const nomeProfessor = sessionStorage.getItem('user') !== null ? sessionStorage.getItem('user') : ''
    const anoPermitido = localStorage.getItem('Year')
    const baseUrl = ApiFilter(sub)
    const meses = [
        { id: 1, Mes: 'JANEIRO' },
        { id: 2, Mes: 'FEVEREIRO' },
        { id: 3, Mes: 'MARÇO' },
        { id: 4, Mes: 'ABRIL' },
        { id: 5, Mes: 'MAIO' },
        { id: 6, Mes: 'JUNHO' },
        { id: 7, Mes: 'JULHO' },
        { id: 8, Mes: 'AGOSTO' },
        { id: 9, Mes: 'SETEMBRO' },
        { id: 10, Mes: 'OUTUBRO' },
        { id: 11, Mes: 'NOVEMBRO' },
        { id: 12, Mes: 'DEZEMBRO' },
    ]

    let faltasAlunosPush = []

    const getEmpresa = async () => {
        const empresaResult = await GetEmpresa(baseUrl)
        setEmpresa(empresaResult)
    }

    const getGrades = async () => {
        const gradesResult = await GetGrades(baseUrl)
        setGrades(gradesResult)
    }

    const getTurmas = async () => {
        const turmaResult = await GetTurmas(baseUrl)
        setTurmas(turmaResult)
    }

    const getAlunos = async () => {
        const alunosResult = await GetAlunos(baseUrl)
        const alunosOrdenados = OrdemItem(alunosResult, 'Nome')
        setAlunos(alunosOrdenados)
    }

    const getAvaliacoes = async () => {
        const resultado = await GetAvaliacoes(baseUrl)
        setAvaliacoes(resultado)
    }

    const getNotas = async (chave) => {
        const chaveNotas = chave
        const notas = await GetNotas(baseUrl, chave)
    }

    const filterCursos = () => {
        const codCursosFiltro = [...new Set(grades.map(item => item.CodCurso))]
        let cursosPush = []

        for (const codigo of codCursosFiltro) {
            const itemFind = grades.filter(item => item.CodCurso === codigo)[0]
            cursosPush.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso, Sistema: itemFind.Sistema })
        }

        const dadosCursos = []

        cursosPush.filter((item) => {
            const itemFind = item
            dadosCursos.push({ CodCurso: itemFind.CodCurso, Curso: itemFind.Curso, Sistema: itemFind.Sistema })
        })

        setCursosFiltro(dadosCursos)
    }

    useEffect(() => {
        setDataAno(DataCompleta[2])
        setDataMes(DataCompleta[1])
        setDataDia(DataCompleta[0])
    }, [DataCompleta])


    async function handleGetFaltas(alunos, disciplina, curso, turma, dataInicial, dataFinal, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, checkAssFrequencia) {
        faltasAlunosPush = []

        async function getFaltasAlunos() {
            faltasAlunosPush = []
            await Axios.post(`${baseUrl}/getFaltasGerarDiario`, {
                alunos: alunos,
                dataInicial: dataInicial,
                dataFinal: dataFinal,
                disciplina: disciplina
            })
                .then((response) => {
                    faltasAlunosPush.push(response.data)
                    setFaltasAlunos(response.data)
                    handleGetConteudo(alunos, dataInicial, dataFinal, curso, disciplina, turma, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, faltasAlunosPush, checkAssFrequencia)
                })
                .catch((error) => {
                    console.log('Erro: ', error)
                })
        }

        await getFaltasAlunos()
    }

    async function handleGetConteudo(alunos, dataInicial, dataFinal, curso_id, disciplina_id, turma_id, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, faltasAlunosPush, checkAssFrequencia) {
        let conteudoPush = []
        console.log('AtualG: ', paginaAtualGlobal, ' TotalG: ', paginasTotaisGlobal)

        //CONTEUDO GET
        async function getConteudo() {
            conteudoPush = []
            await Axios.post(`${baseUrl}/getConteudoGerarDiario`, {
                dataInicial: dataInicial,
                dataFinal: dataFinal,
                curso_id: curso_id,
                disciplina_id: disciplina_id,
                turma_id: turma_id
            })
                .then((response) => {
                    for (let valor of response.data) {
                        conteudoPush.push(valor)
                    }
                    console.log('Query GetConteudo: ', conteudoPush)
                    setConsultaConteudo(conteudoPush)
                    consultarDiario(alunos, response.data, curso_id, disciplina_id, turma_id, dataInicial, dataFinal, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, faltasAlunosPush, checkAssFrequencia)
                })
                .catch((error) => {
                    window.alert('Erro na conexão. Favor tentar novamente ou entrar em contato com suporte')
                    console.log(error)
                })
            setConsultaConteudo(conteudoPush)
        }
        await getConteudo()
    }

    async function handleGetConteudoDisciplinasKeys(baseUrl, dataInicial, dataFinal, curso_id, turma_id) {

        const resultado = await Axios.post(`${baseUrl}/getConteudoDisciplinasKeys`, {
            dataInicial: dataInicial,
            dataFinal: dataFinal,
            curso_id: curso_id,
            turma_id: turma_id
        })

        return resultado.data
    }

    async function consultarDiario(alunos, conteudo, curso, disciplina, turma, dataInicial, dataFinal, paginaAtual, paginasTotais, empresa, anoLetivo, tipoCurso, nomeMes, tipoDiario, faltasAlunosPush, checkAssFrequencia) {
        const returnDisciplinaFiltro = filtroDisciplina(curso)
        const formulario = { curso: curso, disciplina: disciplina, turma: turma, dataInicial: dataInicial, dataFinal: dataFinal }

        const nomeCurso = await cursosFiltro.filter(item => item.CodCurso === curso)[0]?.Curso
        const nomeDisciplina = await disciplina === '' ? 'POLIVALENTE' : returnDisciplinaFiltro.filter(item => item.CodDisciplina === disciplina)[0]?.Disciplina

        const dataAtualPDF = `${DataAno}${DataMes}${DataDia}`

        let conteudoFiltroData = conteudo

        for (let item of conteudoFiltroData) {
            item.data = item.data.slice(0, 10)
            //2021-06-17
            let dividirData = item.data.split('-')
            //["2021","06","17"]
            item.data = `${dividirData[2]}/${dividirData[1]}/${dividirData[0]}`
        }

        alunos.filter( (item) => {
            const notaItem = notas?.filter(nota => nota.matricula === item.Matricula)[0]
            const nota = notaItem?.nota
            const matriculaNota = notaItem?.matricula
            if(item.Matricula === matriculaNota) { 
                item['nota'] = nota
                return item 
            } else {
                return 'false'
            }
        } )

        await Axios.post(`${baseUrl}/gerarDiario`, {
            escola: escola,
            idProfessor: idProfessor,
            nomeProfessor: nomeProfessor,
            data: dataAtualPDF,
            nomeCurso: nomeCurso,
            nomeDisciplina: nomeDisciplina,
            formulario: formulario,
            alunos: alunos,
            conteudo: conteudoFiltroData,
            aulas: conteudo.aulas,
            alunosFaltas: faltasAlunosPush,
            paginaAtual: paginaAtual,
            paginasTotais: paginasTotais,
            empresa: empresa,
            anoLetivo: anoLetivo,
            tipoCurso: tipoCurso,
            nomeMes: nomeMes,
            tipoDiario: tipoDiario,
            avaliacaoAbreviatura: avaliacaoAbreviatura,
            checkAssFrequencia: checkAssFrequencia
            
        })
            .then((response) => {
                const resposta = response.data
                console.log('Query Diario Response antes: ', resposta)

                if (resposta === 'Feito') {
                    //console.log('AtualF: ', paginaAtualGlobal, ' TotalF: ', paginasTotaisGlobal, ' FEITO: ', resposta)
                    window.open(`${baseUrl}/download/?idProfessor=${idProfessor}&data=${dataAtualPDF}`, '_blank')
                }
                //window.location.replace(window.location.origin + '/Diario/Gerar')
                // setLoading(false)
            })
            .catch((error) => {
                try {
                    console.log('Query OPEN CATCH: ', error)
                    setTimeout(() => {
                        // window.open(`${baseUrl}/download/?idProfessor=${idProfessor}&data=${dataAtualPDF}`, '_blank')
                        // setLoading(false)
                    }, 2500)
                } catch {
                    console.log(`${escola} FALHA AO ATUALIZAR CONTEUDO`, error)
                    console.log(error)
                    // setLoading(false)
                }
            })
    }

    const filtroDisciplina = (valorCurso) => {
        const dadosDisciplina = []
        const cursosFiltro = grades.filter(item => item.CodCurso === valorCurso)

        cursosFiltro.filter((item) => {
            const itemFind = item
            dadosDisciplina.push({ CodDisciplina: itemFind.CodDisciplina, Disciplina: itemFind.Disciplina })
        })

        setDisciplinaFiltro(dadosDisciplina)
        return dadosDisciplina
    }

    const onSubmit = async (dados) => {
        //VALIDAR SE DADOS FORAM PREENCHIDOS
        if (
            dados.curso === "9999" ||
            dados.disciplina === "6666" ||
            dados.turma === "4444" ||
            dados.Mes === "6666" ||
            dados.Empresa === "6666"
        ) {
            window.alert('FAVOR VERIFICAR SE TODOS OS CAMPOS FORAM PREENCHIDOS')
            return

        } else {
            setOpen(true)
            setProcessing(true)

            console.log('dados: ', dados)

            const alunos = JSON.parse(dados.alunos)
            const curso = dados.curso                         // CODIGO CURSO
            const disciplina = dados.disciplina               // CODIGO DISCIPLINA
            const turma = dados.turma
            const avaliacao = dados?.avaliacao !== undefined ? JSON.parse(dados?.avaliacao)?.Codigo : ''
            const avAbreviatura = dados?.avaliacao !== undefined ? JSON.parse(dados?.avaliacao)?.Abreviatura : ''
            const dataMes = faixaDataMes(parseInt(dados.Mes)) //DATA CONVERTIDA (2022-01-31)
            const empresa = dados.Empresa
            const nomeCurso = cursosFiltro.filter(item => item.CodCurso === curso)[0].Curso.toUpperCase()
            const checkAssFrequencia = dados.CheckAssFrequencia
            let tipoCurso = ''
            const tipoDiario = 'T'
            const checkEmBranco = dados.CheckEmBranco
            checkEmBrancoAtual = checkEmBranco
            
            avaliacaoAbreviatura = avAbreviatura
            const chave = curso+turma+disciplina+avaliacao
            notas = await GetNotas(baseUrl, chave)

            const disciplinasKeysResultado = await handleGetConteudoDisciplinasKeys(baseUrl, dataMes.dataInicial, dataMes.dataFinal, curso, turma)

            console.log('NomeCurso: ', nomeCurso)
            if (nomeCurso.includes('FUNDAMENTAL')) {
                tipoCurso = 'FUNDAMENTAL'

            } else if (nomeCurso.includes('MÉDIO') || nomeCurso.includes('MEDIO')) {
                tipoCurso = 'MEDIO'

            } else {
                tipoCurso = 'INFANTIL'
            }

            console.log('tipoCurso: ', tipoCurso)

            let disciplinasTotais = filtroDisciplina(curso)

            disciplinasTotais.push({ CodDisciplina: '999', Disciplina: 'POLIVALENTE' }) //ADD POLIVALENTE
            disciplinasTotais = checkEmBrancoAtual ? disciplinasKeysResultado : disciplinasTotais

            const lengthDisciplinas = disciplinasTotais.length - 1 //Quantidade total de disciplinas (Começando do index 0)
            paginasTotaisGlobal = lengthDisciplinas

            if (window.confirm('Gerar relatório?')) {
                setOpen(true)
                setProcessing(true)
                //OPÇÃO TODOS OS CURSOS
                if (disciplina === '666') {

                    for (const disciplinaItem of disciplinasTotais) {
                        const propDisciplina = checkEmBrancoAtual ? 'disciplina_id' : 'CodDisciplina'
                        //CODIGO DISCIPLINA DISCIPLINA
                        const disciplina = disciplinaItem[propDisciplina] === '999' ? '' : disciplinaItem[propDisciplina]
                        const nomeDisciplina = disciplinaItem[propDisciplina] === '999' ? 'POLIVALENTE' : disciplinaItem.Disciplina
                        const alunosOp = await GetAlunosOptativas(baseUrl, disciplina)

                        let alunosSend
                        if (alunosOp?.length > 0 && alunosOp?.length !== undefined) {
                            alunosSend = alunos?.filter(aluno => alunosOp.filter(alunoArr => alunoArr.Matricula === aluno.Matricula).length)
                        } else {
                            alunosSend = alunos
                        }
                        
                        //INDEX DISCIPLINA
                        const indexDisciplina = disciplinasTotais.findIndex(item => item[propDisciplina] === disciplinaItem[propDisciplina])
                        paginaAtualGlobal = indexDisciplina
                        
                        await handleGetFaltas(
                            alunosSend,
                            disciplina,
                            curso,
                            turma,
                            dataMes.dataInicial,
                            dataMes.dataFinal,
                            indexDisciplina,
                            lengthDisciplinas,
                            empresa,
                            anoPermitido,
                            tipoCurso,
                            dataMes.nomeMes,
                            tipoDiario,
                            checkAssFrequencia
                        )
                    }

                    handleClose()
                    setProcessing(false)

                } else {      
                    console.log('alunos: ', alunos)

                    await handleGetFaltas(
                        alunos,
                        disciplina,
                        curso,
                        turma,
                        dataMes.dataInicial,
                        dataMes.dataFinal,
                        lengthDisciplinas,
                        lengthDisciplinas,
                        empresa,
                        anoPermitido,
                        tipoCurso,
                        dataMes.nomeMes,
                        tipoDiario,
                        checkAssFrequencia
                    )

                    handleClose()
                    setProcessing(false)
                }

            }

            handleClose()
            setProcessing(false)
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    useEffect(() => {
        getAlunos()
        getEmpresa()
        getGrades()
        getTurmas()
        getAvaliacoes()
    }, [])

    useEffect(() => {
        filterCursos()
    }, [grades])

    return (
        <>
            <div id='RelatorioCompletoGeneralContainer'>
                <SideBar />

                <div className='RelatorioCompletoContainer'>
                    <Header />

                    <div id="RelatorioCompletoTitlePage">
                        RELATÓRIO COMPLETO
                    </div>

                    <div className="RelatorioCompletoPainelContainer">
                        <FormRelatorioAdmin
                            grades={grades}
                            cursos={cursosFiltro}
                            empresa={empresa}
                            meses={meses}
                            turmas={turmas}
                            alunos={alunos}
                            avaliacoes={avaliacoes}
                            register={register}
                        />

                        {
                            processing === false ?
                                <input type="submit"
                                    name="button"
                                    disabled={processing}
                                    className={`button`}
                                    id="confirmar"
                                    value={'Confirmar'}
                                    onClick={handleSubmit(onSubmit)}
                                    ref={register}
                                />
                                :
                                <input type="submit"
                                    name="button"
                                    disabled={processing}
                                    className={`disabled`}
                                    id="confirmar"
                                    value={'PROCESSANDO'}
                                />
                        }

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </div>

                </div>

            </div>
        </>
    )
}