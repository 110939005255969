export const FiltrarQuaPorCursoETurma = (gradesQuaFiltro, digitacaoProf, valorCurso, valorTurma) => {
    const permissaoDisciplinas = []
    const digitacaoProfQuaTurma = digitacaoProf.filter(item => item.Turma === valorTurma && item.CodCurso === valorCurso)

    for (const itemDigita of digitacaoProfQuaTurma) {
        const result = gradesQuaFiltro.filter(item => item.Codigo === itemDigita.CodDisciplina)

        if (result.length > 0) {
            permissaoDisciplinas.push(result[0])
        }
    }
    return permissaoDisciplinas
}